import React from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

export const quickSampleData = {
  headers: [
    {
      text: "header won",
      transKey: "header1",
    },
    {
      text: "header too",
      transKey: "header2",
    },
  ],
  rows: [
    [
      {
        rowSpan: 2,
        text: "row text",
        transKey: "rowText",
      },
      {
        text: "row text",
        transKey: "rowText",
      },
    ],
    [
      {
        text: "row text",
        transKey: "rowText",
      },
    ],
    [
      {
        rowSpan: 2,
        text: "row text",
        transKey: "rowText",
      },
      {
        text: "row text",
        transKey: "rowText",
      },
    ],
    [
      {
        text: "row text",
        transKey: "rowText",
      },
    ],
  ],
};

/**
 * shows a quick and customizable data table
 * @param {array} headers - table headers
 * @param {array} rows - table rows and cells in a multidimensional array
 * @param {HTMLElement|ReactElement} [as="table"] - element type to render (e.g., import("react-bootstrap").Table)
 * @param {object} [tableAttributes={}] - object of attributes (e.g. className, id, striped, bordered, etc.)
 * @returns {HTMLElement}
 */
const QuickDataTable = ({
  headers = quickSampleData.headers,
  rows = quickSampleData.rows,
  as = "table",
  tableAttributes = {},
}) => {
  const QuickTable = as;
  const { t } = useTranslation();

  return (
    <QuickTable {...tableAttributes}>
      <thead>
        <tr>
          {headers.map(({ text, transKey }, index) => (
            <th key={index}>
              <Trans i18nKey={transKey}>{text}</Trans>
            </th>
          ))}
        </tr>
        {rows.map((cells, index) => (
          <tr key={index}>
            {cells.map(({ rowSpan, colSpan, text, transKey }, index) => (
              <td
                key={index}
                rowSpan={rowSpan}
                colSpan={colSpan}
                dangerouslySetInnerHTML={{
                  __html: transKey ? t(transKey) : text,
                }}
              />
            ))}
          </tr>
        ))}
      </thead>
    </QuickTable>
  );
};

QuickDataTable.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array,
  as: PropTypes.any,
  tableAttributes: PropTypes.object,
};

export default QuickDataTable;
