import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faMapMarkerQuestion,
} from "@fortawesome/pro-light-svg-icons";
import Layout from "../components/Layout";
import SidebarAccount from "../components/SidebarAccount";
import FaqAccordion from "../components/FaqAccordion";
import { regionRequest } from "actions";

const Help = (props) => {
  const {
    dispatch,
    region_data: { unique_region_text },
  } = props;
  const { language } = useI18next();
  const { i18n } = useTranslation();

  useEffect(() => {
    document.dir = i18n.dir();
    dispatch(regionRequest(language));
  }, [dispatch, language, i18n]);

  return (
    <Layout pageName={`help ${language === "ar" ? "custom-rtl-dir" : ""}`}>
      <Helmet>
        <title>{unique_region_text?.siteTitle ?? ""}</title>
      </Helmet>
      <main className="section-container help-container">
        <header id="documentation-and-help">
          <h1 className="h2">
            <FontAwesomeIcon className="mr-3" icon={faQuestionCircle} />
            <Trans i18nKey="helpTitle">Documentation and Help</Trans>
          </h1>
        </header>
        <section className="help-section" id="faqs">
          <h2>
            <FontAwesomeIcon className="mr-3" icon={faMapMarkerQuestion} />
            <Trans i18nKey="faqsTitle">Methodology and FAQs</Trans>
          </h2>
          <p>
            <Trans i18nKey="faqsDescription">
              This section of the Documentation and Help Page describes our
              methodology behind creating the Iraq Water Security geoboard,
              including the selection of indicators and datasets, dataset
              processing, and various other Frequently Asked Questions derived
              from user testing sessions. Preceding the start of development, a
              literature review was conducted by the Innovation Cell’s research
              team. The research team found and analyzed
              environmentally-purposed satellite datasets in order to derive the
              best combination of indicators and supporting datasets, given the
              intended purpose of the Iraq Water Security board.
            </Trans>
          </p>
          <FaqAccordion rtlStyling={language === "ar"} />
        </section>
      </main>
      <SidebarAccount {...props} />
    </Layout>
  );
};

export default connect((state) => state)(Help);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
